import { default as index5LCBeWr5ZoMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/index.vue?macro=true";
import { default as loginrkSMq2ou1eMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/login.vue?macro=true";
import { default as _91id_93fF1zVnDwgiMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/[id].vue?macro=true";
import { default as createf0UjR5NkaWMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/create.vue?macro=true";
import { default as indexlHe9uO5AOSMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/index.vue?macro=true";
import { default as print_45jobsZvMRUS7tDkMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/print-jobs.vue?macro=true";
import { default as indexUiSlIWcHYKMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/reservations/index.vue?macro=true";
import { default as lanesjji4Vqi1NVMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/reservations/lanes.vue?macro=true";
import { default as settlementZbXvi8aqKtMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/settlement.vue?macro=true";
import { default as _91merchantId_93cusTHYETkjMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/setup/[merchantId].vue?macro=true";
import { default as indexkHM5CEHZn7Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/setup/index.vue?macro=true";
import { default as editnAX2Ea4ih4Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexlClukM8lUKMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createXZ409YtxN8Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/create.vue?macro=true";
import { default as indexbn75ZMJu1MMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statusesMnGJoo1NGjMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses.vue?macro=true";
import { default as indexgfdhZ9CDwYMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/index.vue?macro=true";
import { default as createtXFfAqAtnpMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/statuses/create.vue?macro=true";
import { default as createhTfnAMEQVoMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/transfers/create.vue?macro=true";
import { default as indexvSxhWJBliGMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitXoMFXXT4DeMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93q05YVJ26YpMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createbaOi2EUipkMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93argdES1GZLMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId].vue?macro=true";
import { default as indexivrhOMkBBVMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/index.vue?macro=true";
import { default as indexmheyHzYwVbMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitCz7B4DKH9WMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93qkfZ1roY8YMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id].vue?macro=true";
import { default as createyWzCQ152SfMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/create.vue?macro=true";
import { default as index5obzAktCYfMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginrkSMq2ou1eMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: _91merchantId_93cusTHYETkjMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/setup/[merchantId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexkHM5CEHZn7Meta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45statusesMnGJoo1NGjMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93q05YVJ26YpMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qkfZ1roY8YMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250124222902/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]