export const useOrderLine = (orderLine) => {
  const grossPrice =
    orderLine.quantity *
    orderLine.variant_lines.reduce((price, variantLine) => {
      return (
        price +
        variantLine.unit_price +
        variantLine.modifier_lines.reduce(
          (price, modifierLine) =>
            price + modifierLine.unit_price * modifierLine.quantity,
          0,
        )
      );
    }, 0);

  const orderLineDiscountLinePrice = (orderLine.discount_lines || [])
    .filter((discountLine) => !discountLine.cancelled_at)
    .reduce((total, discountLine) => total + discountLine.price, 0);

  const ownPrice = grossPrice - orderLineDiscountLinePrice;

  const isValid = orderLine.variant_lines.every(
    (variantLine) =>
      variantLine.variant_id !== null && variantLine.unit_price !== null,
  );

  return {
    grossPrice,
    ownPrice,
    orderLineDiscountLinePrice,
    isValid,
  };
};
