import { default as indexMDkvigYc6qMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/index.vue?macro=true";
import { default as logincHwTbSN0X5Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/login.vue?macro=true";
import { default as _91id_938SDBcfiSXjMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/[id].vue?macro=true";
import { default as createw1Y5BR9r9zMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/create.vue?macro=true";
import { default as indexd7WXMCKPVSMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/index.vue?macro=true";
import { default as print_45jobsBez1WVjCCGMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/print-jobs.vue?macro=true";
import { default as indexW9MurrLFPQMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/reservations/index.vue?macro=true";
import { default as lanesvc6BuiNBIBMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/reservations/lanes.vue?macro=true";
import { default as settlementNMoa20CqM4Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/settlement.vue?macro=true";
import { default as _91merchantId_93fm0deml4kmMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/setup/[merchantId].vue?macro=true";
import { default as indexjqa9RXSMrjMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/setup/index.vue?macro=true";
import { default as edit7TGgC05zFWMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexN6eLNguJUNMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createts9wmX5MXmMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/create.vue?macro=true";
import { default as indexmQRzetK6tBMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statuseswLqNtPj961Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses.vue?macro=true";
import { default as indexnPU5EeSK8XMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/index.vue?macro=true";
import { default as createAhy6H8LknrMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/statuses/create.vue?macro=true";
import { default as createWNFXSIvZshMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/transfers/create.vue?macro=true";
import { default as indexiJg7b2IJVDMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitrAF0yhjAJ5Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93EAiJUZ0BSzMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createJ1D0XHroi6Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93qcrOtpBRHkMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId].vue?macro=true";
import { default as index0ow5TpZ6LdMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/index.vue?macro=true";
import { default as indexrlgksAFiHMMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as split2i0jsCQMA0Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93WrLWlwwhQUMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id].vue?macro=true";
import { default as createZxU2pKSagIMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/create.vue?macro=true";
import { default as index0Bb9YN5ZtCMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logincHwTbSN0X5Meta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: _91merchantId_93fm0deml4kmMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/setup/[merchantId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexjqa9RXSMrjMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45statuseswLqNtPj961Meta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93EAiJUZ0BSzMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WrLWlwwhQUMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250127095051/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]